import axios from "axios";
import { useCallback } from "react";

const useHTTP = () => {
  const sendRequest = useCallback(async (requestConfig, processData = (e) => { }) => {
    const { url, method = 'GET', body = null, headers = {} } = requestConfig;
    let response = null;
    let responseError = null;
    try {
      if (method === 'GET') {
        response = await axios.get(url);
      }
      else if (method === 'POST') {
        response = await axios.post(url, body, { headers });
      }
      else if (method === 'DELETE') {
        response = await axios.delete(url, { headers });
      }
      processData(response);
    } catch (err) {
      responseError = err;
    }
    return { response, responseError };
  },[]);

  return { sendRequest };
};


export default useHTTP;