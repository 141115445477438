import Multiselect from "multiselect-react-dropdown";

export default function MultiSelect(props) {
  const {
    onKeyPressFn,
    onRemove,
    onSearch,
    onSelect,
    options,
    isObject,
    displayValue,
    value,
    name,
  } = props;
  const defaultFunction = () => {};
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 pb-3">
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        {props.title}
      </label>

      <Multiselect
        isObject={isObject || true}
        onKeyPressFn={onKeyPressFn || defaultFunction}
        onRemove={(e) => onRemove({ name: name, values: e }) || defaultFunction}
        onSearch={(e) => onSearch({ name: name, values: e }) || defaultFunction}
        onSelect={(e) => onSelect({ name: name, values: e }) || defaultFunction}
        options={options || []}
        displayValue={displayValue || "name"}
        name={props.name}
        selectedValues={value}
        placeholder=""
        className={
          "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
        }
        style={{
          multiselectContainer: {
            'minWidth': "210px",
          },
        }}
      />
    </div>
  );
}
