import React, { useState } from "react";
import axios from "axios";
import AuthContext from "../contexts/AuthContext";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const regereAuthProvider = {
  isAuthenticated: false,
  signin(callback) {
    regereAuthProvider.isAuthenticated = true;

    setTimeout(callback, 1); // fake async
  },
  signout(callback) {
    regereAuthProvider.isAuthenticated = false;
    setTimeout(callback, 1);
  }
};

function AuthProvider({ children }) {
  let [user, setUser] = useState(null);

  let signin = (newUser, callback) => {
    return regereAuthProvider.signin(() => {
      axios({
        method: 'post',
        url: `${API_BASE_URL}/users/login`,
        data: {
          email: newUser.username,
          password: newUser.password
        }
      }).then(function (response) {
        // Set access token and uid in cookies
        document.cookie = `uid=${response.data.uid};`;
        document.cookie = `access_token=${response.data.access};`
        document.cookie = `clientName=${response.data.clients[0]}`
        setUser({
          uid: response.data.uid,
          accessToken: response.data.access,
          clientName: response.data.clients[0]
        });

        callback();
      }).catch(function (error) {
        console.log(error);
      });
    });
  };

  let signout = (callback) => {
    return regereAuthProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}




export default AuthProvider;