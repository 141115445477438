import {
  API_BASE_URL,
  BULK_UPLOAD_ROUTE_PATH
} from "../../utils/constants";

export const JOBS_API_URL = `${API_BASE_URL}/jobs`;

export const items = [
  { name: 'Bulk Upload', href: BULK_UPLOAD_ROUTE_PATH },
  { name: 'Export as Excel', href: '#' },
]
export const ALLOWED_PRIORITY = ['Normal', 'High'];
export const ALLOWED_STATUS = ['Active', 'Decommissioned', 'Under repair'];
export const DEFAULT_CONTROLLERS = ['Controller 1', 'Controller 2'];
export const DEFAULT_CLIENTS = ["C&L", "Chavez", "MLE", "CF", "PMR", "H&C", "NCIME", "JH", "Netmark"];
export const DEFAULT_JOB_TYPES = ["Initial", "Additional"];
export const DEFAULT_PROJECT = ["CPAP", "3MM", "Railroad", "Herniamesh"];
export const DEFAULT_WORK_TYPES = ["SpreadSheet", "Chronology", "Narrative"];

export const DEFAULT_EQUIPMENT_TYPES = ['Equipment', 'Machine'];
export const DEFAULT_PROTOCOLS = ['SSH', 'Telnet', 'HTTP'];
export const DEFAULT_LOCATIONS = [
  { id: 1, name: "Location 1" },
  { id: 2, name: "Location 2", }
];
export const DEFAULT_TAGS = [
  { id: 1, name: "Tag 1" },
  { id: 2, name: "Tag 2", }
];