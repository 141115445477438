import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { useFormInput } from "../hooks/formHooks";
import AuthContext from "../store/contexts/AuthContext";
import { useLocation, Navigate } from "react-router-dom";
import { getCookie } from "../utils/global";
import { DASHBOARD_PATH } from "../utils/constants";

import mainLogo from '../Big-logo.png';
import backgroundImage from '../home-bg-mobile.jpg';

export default function Login() {
  let navigate = useNavigate();
  let authContext = useContext(AuthContext);
  const username = useFormInput("");
  const password = useFormInput("");

  const [authenticating, setAuthenticating] = useState(false);

  // ################
  // Check if it is already authenticated
  // ################
  let location = useLocation();
  const uid = getCookie("uid");
  const accessToken = getCookie("access_token");
  const clientName = getCookie("clientName");
  if (accessToken && uid && clientName) {
    return <Navigate to={DASHBOARD_PATH} state={{ from: location }} replace />;
  }
  // ################
  // Check if it is already authenticated -- ENDS
  // ################

  function onClickLogin(event) {
    event.preventDefault();
    setAuthenticating(true);
    if (username.value && password.value) {
      authContext.signin(
        { username: username.value, password: password.value },
        () => {
          // Send them back to the page they tried to visit when they were
          // redirected to the login page. Use { replace: true } so we don't create
          // another entry in the history stack for the login page.  This means that
          // when they get to the protected page and click the back button, they
          // won't end up back on the login page, which is also really nice for the
          // user experience.
          // ALWAYS GO TO HOME POST LOGIN
          navigate(DASHBOARD_PATH, { replace: true });
        }
      );
    } else {
      console.warn("Username or password is not entered");
    }
  }
  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8" style={{
        backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat',
         backgroundSize: "cover",
         backgroundPosition: "right bottom"
      }}>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img src={mainLogo} alt="Netmark Business services"/>
          <h2 className="mt-32 text-center text-2xl font-extrabold text-gray-900">
            Sign in to your customer account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6">
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700"
                >
                  Username
                </label>
                <div className="mt-1">
                  <input
                    type="input"
                    {...username}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    type="password"
                    {...password}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                {authenticating ? (
                  <button
                    type="button"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled
                  >
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Authenticating ...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={onClickLogin}
                  >
                    Login
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
