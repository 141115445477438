import { Outlet } from "react-router-dom";
/* This example requires Tailwind CSS v2.0+ */

import Notification from "../../components/ui/alerts/Notification";
import { useSelector } from "react-redux";

// const pages = [
//   { name: "Job", href: JOB_FULL_PATH, current: true },
//   { name: "New Job", href: JOB_NEW_FULL_PATH, current: false },
// ];

export default function JobLayout() {
  const notification = useSelector((state) => state.notification);

  return (
    <div className="w-full overflow-y-scroll">
      <Notification
        type={notification.type}
        message={notification.message}
        title={notification.title}
        status={notification.status}
      />
      {/* <nav className="bg-white border-b border-gray-200 flex " aria-label="Breadcrumb">
        <ol className="max-w-screen-xl w-full px-4 flex space-x-4 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <a href={DASHBOARD_PATH} className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name} className="flex">
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 w-6 h-full text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <a
                  href={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav> */}
      <Outlet />
    </div>
  );
}
