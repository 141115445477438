/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../../components/ui/forms/FileUpload";
import useHTTP from "../../../hooks/useHTTP";
import { JOB_FULL_PATH } from "../../../utils/constants";
import { API_BASE_URL } from "../../../utils/constants";

export default function BulkUploadJobs() {
  const navigate = useNavigate();
  const { sendRequest } = useHTTP();
  const [fileState, setFile] = useState(null);

  const uploadFile = async (e) => {
    const formData = new FormData();
    formData.append("file", fileState, fileState.name);

    const { response } = await sendRequest({
      url: `${API_BASE_URL}/jobs/bulkupload`,
      method: "POST",
      body: formData,
    });

    if (response && response.status) {
      navigate(JOB_FULL_PATH);
    } else {
    }
  };

  return (
    <FileUpload
      buttonText="Confirm"
      title="Bulk Upload Jobs"
      description="Upload a CSV file."
      name="bulk-upload-jobs"
      onChange={(e) => {
        const file = e.target.files[0];
        setFile(file);
      }}
      uploadedFileName={fileState ? fileState.name : null}
      onSubmit={uploadFile}
    />
  );
}
