import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        title: "",
        message: '',
        type: '',
        show: false,
    },
    reducers: {
        setNotification: (state, action) => {
            state.title = action.payload.title;
            state.message = action.payload.message;
            state.type = action.payload.type;
            state.status = action.payload.status;
        }
    }
});

export const triggerNotification = (title, message, success) => {
    return (dispatch) => {
        const payload = {
            title: title,
            message: message,
            type: success ? 'success' : 'error',
            status: true,
        }
        dispatch(notificationSlice.actions.setNotification(payload));
        setTimeout(() => {
            const payload = {
                title: "",
                message: "",
                type: success ? 'success' : 'error',
                status: false,
            }
            dispatch(notificationSlice.actions.setNotification(payload));
        }, 2000);
    }
}

export const { showNotification } = notificationSlice.actions;
export default notificationSlice;