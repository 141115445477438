import { useEffect, useState } from "react";
import { BadgeLarge } from "../../../components/ui/badges/BadgeLarge";

import { getJobs, deleteJob, getComments } from "../../../store/modules/jobs/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDeleteModal from "../../../components/ui/modals/ConfirmDeleteModal";
import Comments from "./Comments";
import JobFilters from "./JobFilters";

export default function Job() {
  // const navigate = useNavigate();
  const jobs = useSelector((state) => state.jobs.jobs);
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch();
  const [jobId, setJobId] = useState(null);
  useEffect(() => {
    dispatch(getJobs());
  }, [dispatch]);

  const _deleteJob = async (data) => {
    dispatch(deleteJob(data.id));
  };

  const _showComments = async (jobId) => {
    dispatch(getComments(jobId))
    setOpen(true);
    setJobId(jobId)
  }

  const HEADERS = [
    "Patient",
    "Job Type",
    "Project / Work Type",
    "ETA",
    "Current Stage",
    "Input Pages",
    "Invoice Date",
  ];

  const locations = (locations) => {
    if (!locations) {
      return (
        <div>
          <BadgeLarge title={"-"} isDot={false} className={"ml-2 mt-1"} />
          <br />
        </div>
      );
    }
    if (locations && Array.isArray(locations)) {
      return (
        <div>
          {locations.map((location) => (
            <BadgeLarge
              title={location.name}
              isDot={true}
              className={"ml-2 mt-1"}
            />
          ))}
          <br />
        </div>
      );
    } else if (locations && typeof locations === "string") {
      return (
        <div>
          <BadgeLarge title={locations} isDot={true} className={"ml-2 mt-1"} />
          <br />
        </div>
      );
    }
  };

  const constructDate = (date) => {
    if(date) {
      date = new Date(date);
      return `${("0" + (date.getMonth() + 1)).slice(-2)}-${("0"+(date.getDate())).slice(-2)}-${date.getFullYear().toString().substr(-2)}`
    }
    return ""
  }

  return (
    <>
      <ConfirmDeleteModal
        title="Delete Job"
        message="Are you sure you want to delete this job?"
        onConfirm={(data) => {
          _deleteJob(data);
        }}
      />

      <div className="relative p-5 border-b border-gray-200 sm:pb-0 overflow-y-scroll w-full">
        <div className="md:flex md:items-center md:justify-between">
          <div>
          <JobFilters />
          </div>
          <div className="p-3 flex md:mt-0 md:absolute md:top-3 md:right-0">
            
            {/* <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <button
                type="button"
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={async (event) => {
                  alert("TBD");
                }}
              >
                Export
              </button>
            </span> */}
            {/* <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <button
                type="button"
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-l-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  navigate(NEW_ROUTE_PATH);
                }}
              >
                Create New Job
              </button>
              <Menu as="span" className="-ml-px relative block">
                <Menu.Button className="inline-flex items-center px-4 py-2 border border-transparent rounded-r-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {items.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                              to={item.href}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </span> */}
          </div>
        </div>
        <div className="mt-4">
          {/* Start of table */}
          <div className="h-full">
            <table className="min-w-full divide-gray-200 table-auto mt-6">
              <thead className="bg-gray-50">
                <tr>
                  {HEADERS.map((header, index) => (
                    <th
                    key = {index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}

                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                  <th scope="col" className="relative px-6 py-3"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {jobs &&
                  jobs.map((job) => (
                    <tr key={job.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {job.patientName}
                          <p className="text-xs">{job.clientName}</p>
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {job.jobType}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {job.project}
                          <p>{job.workType}</p>
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{job.eta ? constructDate(job.eta) : ""}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {job.stages.filter(
                            (stage) => stage.name === job.currentStage
                          )[0].showToCustomer
                            ? locations(job.currentStage)
                            : "Hidden"}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {job.inputPages}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {job.invoiceDate ? constructDate(job.invoiceDate) : ""}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {/* <button
                          type="button"
                          className="inline-flex items-center px-2.5 mr-4 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          View
                        </button> */}
                        <button
                          type="button"
                          key={job.id}
                          onClick={() => _showComments(job.id)}
                          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Comments
                        </button>
                      </td>
                      
                      {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Menu
                          as="div"
                          className="absolute top-6 right-0 xl:relative xl:top-auto xl:right-auto xl:self-center"
                        >
                          <div>
                            <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                              <span className="sr-only">Open options</span>
                              <DotsHorizontalIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="focus:outline-none absolute right-0 z-10 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm text-center"
                                      )}
                                      onClick={() => {
                                        navigate(EDIT_ROUTE_PATH, {
                                          state: job,
                                        });
                                      }}
                                    >
                                      Edit
                                    </button>
                                  )}
                                </Menu.Item>

                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className="hover:text-white hover:bg-red-900 block px-4 py-2 text-sm"
                                      onClick={() => {
                                        dispatch(setModalData({ id: job.id }));
                                        dispatch(setModalOpen(true));
                                      }}
                                    >
                                      Delete
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
            {open ? <Comments 
              key={jobId}
              jobId={jobId} 
              open={open} 
              setOpen={setOpen} 
              title={"Comments"}
            /> : null}
          </div>
          {/* End of user table */}
        </div>
      </div>
    </>
  );
}
