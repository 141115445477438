/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { getComments, addComment } from "../../../store/modules/jobs/jobSlice";

export default function Comments(props) {
    const open = props.open;
    const setOpen = props.setOpen;
    const title = props.title;
    const jobId = props.jobId;
    const [comment, setComment] = useState("")
    const comments = useSelector(state => state.jobs.comments);
    console.log(comments)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getComments(jobId));
    }, [dispatch, jobId]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                        <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                                            <div className="px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-gray-900">{title} </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                {/* Replace with your content */}
                                                <div className="h-full" aria-hidden="true">
                                                    <div>
                                                        <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                                                            Add your comment
                                                        </label>
                                                        <div className="mt-1">
                                                            <textarea
                                                                rows={4}
                                                                name="comment"
                                                                id="comment"
                                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                value={comment}
                                                                onChange={(e) => setComment(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className='mt-4 text-align-right'>
                                                            <button
                                                                type="button"
                                                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                onClick={() => setOpen(false)}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                onClick={() => {
                                                                    dispatch(addComment(jobId, comment));
                                                                    setComment("")
                                                                }}
                                                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            >
                                                                Add comment
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="flow-root mt-6">
                                                        <ul className="-my-5 divide-y divide-gray-200">
                                                            {comments.map((comment, index) => (
                                                                <li key={index} className="py-5">
                                                                    <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                                                                        <h3 className="text-sm font-semibold text-gray-800">
                                                                           
                                                                                {/* Extend touch target to entire panel */}
                                                                                <span className="absolute inset-0" aria-hidden="true" />
                                                                                {comment.comment} 
                                                                            
                                                                        </h3>
                                                                        <p className="mt-1 text-gray-600 line-clamp-2" style={{fontSize: "0.6em"}}>{comment.creator} - {new Date(comment.createdAt).toDateString()}</p>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>

                                                {/* /End replace */}
                                            </div>
                                        </div>

                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
