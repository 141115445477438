import {
  API_BASE_URL
} from "../../utils/constants";

export const USERS_API_URL = `${API_BASE_URL}/users`;

export const tabs = [
  { name: 'All', href: '#', current: true },
  { name: 'Active', href: '#', current: false },
  { name: 'Awaiting Approval', href: '#', current: false },
  { name: 'Inactive', href: '#', current: false }
]

export const items = [
  { name: 'Bulk Upload', href: '#' },
]