import { createSlice } from '@reduxjs/toolkit';
import { triggerNotification } from '../notification';
import { API_BASE_URL } from '../../../utils/constants';
import { sendRequest } from '../../../utils/http';
import { JOBS_API_URL } from '../../../modules/job/constants';
import { getCookie } from "../../../utils/global";

const jobSlice = createSlice({
  name: 'jobs',
  initialState: {
    jobs: [],
    isLoading: false,
    isLoaded: false,
    comments: []
  },
  reducers: {
    setJobs: (state, action) => {
      state.jobs = action.payload.jobs;
    },
    setComments: (state, action) => {
      state.comments = action.payload.comments;
    },
    deleteJob: (state, action) => {
      state.jobs = state.jobs.filter(job => job.id !== action.payload.id);
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload.isLoaded;
    }
  }
});

export const getJobs = (filters) => {
  return async (dispatch) => {
    getCookie("clientName")
    let filterQuery = [];
    const clientName = getCookie("clientName");
    if(clientName === "H&C") {
      filterQuery.push(`clientName=H%26C`);
    } else {
      filterQuery.push(`clientName=${clientName}`);
    }
    if(filters) {
      if (filters.patientName) {
        filterQuery.push(`patientName=${filters.patientName}`);
      }
      if (filters.jobType) {
        filterQuery.push(`jobType=${filters.jobType}`);
      }
      if (filters.workType) {
        filterQuery.push(`workType=${filters.workType}`);
      }
      if (filters.priority) {
        filterQuery.push(`priority=${filters.priority}`);
      }
      if (filters.project) {
        filterQuery.push(`project=${filters.project}`);
      }
      if (filters.currentStage) {
        filterQuery.push(`currentStage=${filters.currentStage}`);
      }
    }
    const { response, responseError } = await sendRequest({
      url: `${JOBS_API_URL}?${filterQuery.join("&")}`,
      method: "GET"
    });
    if (
      response && response.status && response.data.data &&
      response.data.data.jobs && response.data.data.jobs.length
    ) {
      dispatch(jobSlice.actions.setJobs({ jobs: response.data.data.jobs }));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
};

export const deleteJob = (id) => {
  return async (dispatch) => {
    dispatch(jobSlice.actions.setIsLoading({ isLoading: true }));
    const { response, responseError } = await sendRequest({
      url: `${JOBS_API_URL}/${id}`,
      method: "DELETE"
    });
    if (
      response && response.status
    ) {
      dispatch(jobSlice.actions.setIsLoaded({ isLoaded: true }));
      dispatch(jobSlice.actions.deleteJob({ id }));
      dispatch(triggerNotification(
        "Job deleted successfully!",
        response.message,
        "success"
      ));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
};

export const createJobs = (job) => {
  return async (dispatch) => {
    dispatch(jobSlice.actions.setIsLoading({ isLoading: true }));
    const { response, responseError } = await sendRequest({
      url: `${API_BASE_URL}/jobs`,
      method: "POST",
      body: { ...job, currentStage: job.stages.length ? job.stages[0].name : "" }
    });
    if (
      response && response.status
    ) {
      dispatch(jobSlice.actions.setIsLoaded({ isLoaded: true }));
      dispatch(triggerNotification(
        "Job created successfully!",
        response.message,
        "success"
      ));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
};

export const updateJob = (data) => {
  return async (dispatch) => {
    dispatch(jobSlice.actions.setIsLoading({ isLoading: true }));
    const { response, responseError } = await sendRequest({
      url: `${JOBS_API_URL}/${data.id}`,
      method: "POST",
      body: data
    });
    if (
      response && response.status
    ) {
      dispatch(jobSlice.actions.setIsLoaded({ isLoaded: true }));
      dispatch(getJobs());
      dispatch(triggerNotification(
        "Job updated successfully!",
        response.message,
        "success"
      ));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
};

export const addComment = (jobId, comment) => {
  return async (dispatch) => {
    dispatch(jobSlice.actions.setIsLoading({ isLoading: true }));
    const { response, responseError } = await sendRequest({
      url: `${API_BASE_URL}/comments`,
      method: "POST",
      body: { jobId, comment, creator: "kaushik11091@gmail.com" }
    });
    if (
      response && response.status
    ) {
      dispatch(getComments(jobId))
      dispatch(jobSlice.actions.setIsLoaded({ isLoaded: true }));
      dispatch(triggerNotification(
        "Comment created successfully!",
        response.message,
        "success"
      ));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
}

export const getComments = (jobId) => {
  return async (dispatch) => {
    dispatch(jobSlice.actions.setComments({ comments: [] }));
    const { response, responseError } = await sendRequest({
      url: `${API_BASE_URL}/comments?jobId=${jobId}&public=true`,
      method: "GET"
    });
    if (
      response && response.status && response.data.data &&
      response.data.data.jobs && response.data.data.jobs.length
    ) {
      dispatch(jobSlice.actions.setComments({ comments: response.data.data.jobs }));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
};


export const { setJobs, setIsLoaded, setIsLoading } = jobSlice.actions;
export default jobSlice;